@import url('../../node_modules/plyr/dist/plyr.css');
@import url('../../node_modules/react-circular-progressbar/dist/styles.css');
@import url('fonts.css');

* {
  box-sizing: border-box;
}

html,
body {
  font-family: 'Charlie Text', sans-serif;
  padding: 0;
  margin: 0;
}
