html, body, p, div, h1, h2, h3, h4, h5, h6, ul, ol, dl, img, pre, form, fieldset {
  margin: 0;
  padding: 0;
}

img, fieldset {
  border: 0;
}

body, html {
  width: 100%;
  height: 100%;
}

body {
  background-color: var(--ds-surface, #fff);
  color: var(--ds-text, #172b4d);
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -webkit-text-decoration-skip-ink: auto;
  text-decoration-skip-ink: auto;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857;
}

p, ul, ol, dl, h1, h2, h3, h4, h5, h6, blockquote, pre, form, table {
  margin: var(--ds-space-150, 12px) 0 0 0;
}

a {
  color: var(--ds-link, #0052cc);
  text-decoration: none;
}

a:hover {
  color: var(--ds-link, #0065ff);
  text-decoration: underline;
}

a:active {
  color: var(--ds-link-pressed, #0747a6);
}

a:focus-visible {
  outline: var(--ds-border-width-outline, 2px) solid var(--ds-border-focused, #2684ff);
  outline-offset: var(--ds-space-025, 2px);
}

@supports not selector(*:focus-visible) {
  a:focus {
    outline: var(--ds-border-width-outline, 2px) solid var(--ds-border-focused, #4c9aff);
    outline-offset: var(--ds-space-025, 2px);
  }
}

h1 {
  font-size: 2.07143em;
  font-style: inherit;
  color: var(--ds-text, var(--ds-text, #172b4d));
  line-height: 1.10345;
  font-weight: var(--ds-font-weight-semibold, 600);
  letter-spacing: -.01em;
  margin-top: 40px;
}

h2 {
  font-size: 1.71429em;
  font-style: inherit;
  color: var(--ds-text, var(--ds-text, #172b4d));
  line-height: 1.16667;
  font-weight: var(--ds-font-weight-medium, 500);
  letter-spacing: -.01em;
  margin-top: 40px;
}

h3 {
  font-size: 1.42857em;
  font-style: inherit;
  color: var(--ds-text, var(--ds-text, #172b4d));
  line-height: 1.2;
  font-weight: var(--ds-font-weight-medium, 500);
  letter-spacing: -.008em;
  margin-top: 28px;
}

h4 {
  font-size: 1.14286em;
  font-style: inherit;
  color: var(--ds-text, var(--ds-text, #172b4d));
  line-height: 1.25;
  font-weight: var(--ds-font-weight-semibold, 600);
  letter-spacing: -.006em;
  margin-top: 24px;
}

h5 {
  font-size: 1em;
  font-style: inherit;
  color: var(--ds-text, var(--ds-text, #172b4d));
  line-height: 1.14286;
  font-weight: var(--ds-font-weight-semibold, 600);
  letter-spacing: -.003em;
  margin-top: 16px;
}

h6 {
  font-size: .857143em;
  font-style: inherit;
  color: var(--ds-text, var(--ds-text, #172b4d));
  line-height: 1.33333;
  font-weight: var(--ds-font-weight-semibold, 600);
  text-transform: uppercase;
  margin-top: 20px;
}

ul, ol, dl {
  padding-left: var(--ds-space-500, 40px);
}

dd, dd + dt, li + li, ul ul:not(:first-child), ol ul:not(:first-child), ul ol:not(:first-child), ol ol:not(:first-child) {
  margin-top: var(--ds-space-050, 4px);
}

p:first-child, ul:first-child, ol:first-child, dl:first-child, h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child, blockquote:first-child, pre:first-child, form:first-child, table:first-child {
  margin-top: 0;
}

blockquote, q {
  color: inherit;
}

blockquote {
  padding-left: var(--ds-space-500, 40px);
  border: none;
}

[dir="rtl"] blockquote {
  padding-left: 0;
  padding-right: var(--ds-space-500, 40px);
}

blockquote:before, q:before {
  content: "“";
}

blockquote:after, q:after {
  content: "”";
}

blockquote:before {
  float: left;
  text-align: right;
  width: 1em;
  margin-left: -1em;
}

[dir="rtl"] blockquote:before {
  float: right;
  text-align: left;
  margin-right: -1em;
}

blockquote > :last-child {
  display: inline-block;
}

small {
  font-size: .785714em;
  font-style: inherit;
  color: var(--ds-text-subtlest, var(--ds-text-subtlest, #6b778c));
  line-height: 1.45455;
  font-weight: var(--ds-font-weight-bold, 700);
  margin-top: 16px;
  font-weight: normal;
}

code, kbd {
  font-family: SFMono-Medium, SF Mono, Segoe UI Mono, Roboto Mono, Ubuntu Mono, Menlo, Consolas, Courier, monospace;
}

var, address, dfn, cite {
  font-style: italic;
}

abbr {
  border-bottom: 1px var(--ds-border, #ccc) dotted;
  cursor: help;
}

@supports (color-scheme: dark) and (color-scheme: light) {
  [data-color-mode="light"] {
    --lightningcss-light: initial;
    --lightningcss-dark: ;
    color-scheme: light;
  }

  [data-color-mode="dark"] {
    --lightningcss-light: ;
    --lightningcss-dark: initial;
    color-scheme: dark;
  }
}

table {
  border-collapse: collapse;
  width: 100%;
}

thead, tbody, tfoot {
  border-bottom: 2px solid var(--ds-border, #dfe1e6);
}

td, th {
  padding: var(--ds-space-050, 4px) var(--ds-space-100, 8px);
  text-align: left;
  border: none;
}

th {
  vertical-align: top;
}

td:first-child, th:first-child {
  padding-left: 0;
}

td:last-child, th:last-child {
  padding-right: 0;
}

caption {
  font-size: 1.42857em;
  font-style: inherit;
  color: var(--ds-text, var(--ds-text, #172b4d));
  line-height: 1.2;
  font-weight: var(--ds-font-weight-medium, 500);
  letter-spacing: -.008em;
  margin-top: 28px;
  margin-bottom: var(--ds-space-100, 8px);
  text-align: left;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section {
  display: block;
}

@-moz-document url-prefix() {
  img {
    font-size: 0;
  }

  img:-moz-broken {
    font-size: inherit;
  }
}

button {
  font-family: inherit;
}

.assistive {
  clip: rect(1px, 1px, 1px, 1px) !important;
  white-space: nowrap !important;
  border: 0 !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

html, body {
  font-family: Charlie Text, sans-serif;
  overflow-x: hidden;
}

@keyframes plyr-progress {
  to {
    background-position: 25px 0;
    background-position: var(--plyr-progress-loading-size, 25px) 0;
  }
}

@keyframes plyr-popup {
  0% {
    opacity: .5;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes plyr-fade-in {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.plyr {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: inherit;
  font-family: var(--plyr-font-family, inherit);
  font-variant-numeric: tabular-nums;
  font-weight: 400;
  font-weight: var(--plyr-font-weight-regular, 400);
  line-height: 1.7;
  line-height: var(--plyr-line-height, 1.7);
  text-shadow: none;
  z-index: 0;
  direction: ltr;
  flex-direction: column;
  align-items: center;
  min-width: 200px;
  max-width: 100%;
  transition: box-shadow .3s;
  display: flex;
  position: relative;
}

.plyr audio, .plyr iframe, .plyr video {
  width: 100%;
  height: 100%;
  display: block;
}

.plyr button {
  font: inherit;
  line-height: inherit;
  width: auto;
}

.plyr:focus {
  outline: 0;
}

.plyr--full-ui {
  box-sizing: border-box;
}

.plyr--full-ui *, .plyr--full-ui :after, .plyr--full-ui :before {
  box-sizing: inherit;
}

.plyr--full-ui a, .plyr--full-ui button, .plyr--full-ui input, .plyr--full-ui label {
  touch-action: manipulation;
}

.plyr__badge {
  background: #4a5464;
  background: var(--plyr-badge-background, #4a5464);
  border-radius: 2px;
  border-radius: var(--plyr-badge-border-radius, 2px);
  color: var(--plyr-badge-text-color, #fff);
  font-size: 9px;
  font-size: var(--plyr-font-size-badge, 9px);
  padding: 3px 4px;
  line-height: 1;
}

.plyr--full-ui ::-webkit-media-text-track-container {
  display: none;
}

.plyr__captions {
  font-size: 13px;
  font-size: var(--plyr-font-size-small, 13px);
  padding: 10px;
  padding: var(--plyr-control-spacing, 10px);
  text-align: center;
  width: 100%;
  transition: transform .4s ease-in-out;
  animation: .3s plyr-fade-in;
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
}

.plyr__captions span:empty {
  display: none;
}

@media (width >= 480px) {
  .plyr__captions {
    font-size: 15px;
    font-size: var(--plyr-font-size-base, 15px);
    padding: 20px;
    padding: calc(var(--plyr-control-spacing, 10px) * 2);
  }
}

@media (width >= 768px) {
  .plyr__captions {
    font-size: 18px;
    font-size: var(--plyr-font-size-large, 18px);
  }
}

.plyr--captions-active .plyr__captions {
  display: block;
}

.plyr:not(.plyr--hide-controls) .plyr__controls:not(:empty) ~ .plyr__captions {
  transform: translateY(-40px);
  transform: translateY(calc(var(--plyr-control-spacing, 10px) * -4));
}

.plyr__caption {
  background: #000c;
  background: var(--plyr-captions-background, #000c);
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  color: var(--plyr-captions-text-color, #fff);
  white-space: pre-wrap;
  border-radius: 2px;
  padding: .2em .5em;
  line-height: 185%;
}

.plyr__caption div {
  display: inline;
}

.plyr__control {
  border-radius: 3px;
  border-radius: var(--plyr-control-radius, 3px);
  color: inherit;
  cursor: pointer;
  padding: 7px;
  padding: calc(var(--plyr-control-spacing, 10px) * .7);
  background: none;
  border: 0;
  flex-shrink: 0;
  transition: all .3s;
  position: relative;
  overflow: visible;
}

.plyr__control svg {
  fill: currentColor;
  height: var(--plyr-control-icon-size, 18px);
  pointer-events: none;
  width: var(--plyr-control-icon-size, 18px);
  width: 18px;
  height: 18px;
  display: block;
}

.plyr__control:focus {
  outline: 0;
}

.plyr__control.plyr__tab-focus {
  outline: 3px dotted #00b2ff;
  outline: var(--plyr-tab-focus-color, var(--plyr-color-main, var(--plyr-color-main, #00b2ff))) dotted 3px;
  outline-offset: 2px;
}

a.plyr__control {
  text-decoration: none;
}

.plyr__control.plyr__control--pressed .icon--not-pressed, .plyr__control.plyr__control--pressed .label--not-pressed, .plyr__control:not(.plyr__control--pressed) .icon--pressed, .plyr__control:not(.plyr__control--pressed) .label--pressed, a.plyr__control:after, a.plyr__control:before {
  display: none;
}

.plyr--full-ui ::-webkit-media-controls {
  display: none;
}

.plyr__controls {
  text-align: center;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.plyr__controls .plyr__progress__container {
  flex: 1;
  min-width: 0;
}

.plyr__controls .plyr__controls__item {
  margin-left: 2.5px;
  margin-left: calc(var(--plyr-control-spacing, 10px) / 4);
}

.plyr__controls .plyr__controls__item:first-child {
  margin-left: 0;
  margin-right: auto;
}

.plyr__controls .plyr__controls__item.plyr__progress__container {
  padding-left: 2.5px;
  padding-left: calc(var(--plyr-control-spacing, 10px) / 4);
}

.plyr__controls .plyr__controls__item.plyr__time {
  padding: 0 5px;
  padding: 0 calc(var(--plyr-control-spacing, 10px) / 2);
}

.plyr__controls .plyr__controls__item.plyr__progress__container:first-child, .plyr__controls .plyr__controls__item.plyr__time + .plyr__time, .plyr__controls .plyr__controls__item.plyr__time:first-child {
  padding-left: 0;
}

.plyr [data-plyr="airplay"], .plyr [data-plyr="captions"], .plyr [data-plyr="fullscreen"], .plyr [data-plyr="pip"], .plyr__controls:empty {
  display: none;
}

.plyr--airplay-supported [data-plyr="airplay"], .plyr--captions-enabled [data-plyr="captions"], .plyr--fullscreen-enabled [data-plyr="fullscreen"], .plyr--pip-supported [data-plyr="pip"] {
  display: inline-block;
}

.plyr__menu {
  display: flex;
  position: relative;
}

.plyr__menu .plyr__control svg {
  transition: transform .3s;
}

.plyr__menu .plyr__control[aria-expanded="true"] svg {
  transform: rotate(90deg);
}

.plyr__menu .plyr__control[aria-expanded="true"] .plyr__tooltip {
  display: none;
}

.plyr__menu__container {
  background: #ffffffe6;
  background: var(--plyr-menu-background, #ffffffe6);
  border-radius: 4px;
  border-radius: var(--plyr-menu-radius, 4px);
  box-shadow: 0 1px 2px #00000026;
  box-shadow: var(--plyr-menu-shadow, 0 1px 2px #00000026);
  color: var(--plyr-menu-color, #4a5464);
  font-size: 15px;
  font-size: var(--plyr-font-size-base, 15px);
  text-align: left;
  white-space: nowrap;
  z-index: 3;
  margin-bottom: 10px;
  animation: .2s plyr-popup;
  position: absolute;
  bottom: 100%;
  right: -3px;
}

.plyr__menu__container > div {
  transition: height .35s cubic-bezier(.4, 0, .2, 1), width .35s cubic-bezier(.4, 0, .2, 1);
  overflow: hidden;
}

.plyr__menu__container:after {
  border: 4px solid #0000;
  border-top-color: #ffffffe6;
  border: var(--plyr-menu-arrow-size, 4px) solid transparent;
  border-top-color: var(--plyr-menu-background, #ffffffe6);
  content: "";
  right: 14px;
  right: calc(var(--plyr-control-icon-size, 18px) / 2 + var(--plyr-control-spacing, 10px) * .7 - var(--plyr-menu-arrow-size, 4px) / 2);
  width: 0;
  height: 0;
  position: absolute;
  top: 100%;
}

.plyr__menu__container [role="menu"] {
  padding: 7px;
  padding: calc(var(--plyr-control-spacing, 10px) * .7);
}

.plyr__menu__container [role="menuitem"], .plyr__menu__container [role="menuitemradio"] {
  margin-top: 2px;
}

.plyr__menu__container [role="menuitem"]:first-child, .plyr__menu__container [role="menuitemradio"]:first-child {
  margin-top: 0;
}

.plyr__menu__container .plyr__control {
  color: var(--plyr-menu-color, #4a5464);
  font-size: 13px;
  font-size: var(--plyr-font-size-menu, var(--plyr-font-size-small, 13px));
  padding: 4.66667px 10.5px;
  padding: calc(var(--plyr-control-spacing, 10px) * .7 / 1.5) calc(var(--plyr-control-spacing, 10px) * .7 * 1.5);
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  align-items: center;
  width: 100%;
  display: flex;
}

.plyr__menu__container .plyr__control > span {
  align-items: inherit;
  width: 100%;
  display: flex;
}

.plyr__menu__container .plyr__control:after {
  border: 4px solid #0000;
  border: var(--plyr-menu-item-arrow-size, 4px) solid transparent;
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.plyr__menu__container .plyr__control--forward {
  padding-right: 28px;
  padding-right: calc(var(--plyr-control-spacing, 10px) * .7 * 4);
}

.plyr__menu__container .plyr__control--forward:after {
  border-left-color: #728197;
  border-left-color: var(--plyr-menu-arrow-color, #728197);
  right: 6.5px;
  right: calc(var(--plyr-control-spacing, 10px) * .7 * 1.5 - var(--plyr-menu-item-arrow-size, 4px));
}

.plyr__menu__container .plyr__control--forward.plyr__tab-focus:after, .plyr__menu__container .plyr__control--forward:hover:after {
  border-left-color: currentColor;
}

.plyr__menu__container .plyr__control--back {
  font-weight: 400;
  font-weight: var(--plyr-font-weight-regular, 400);
  margin: 7px;
  margin: calc(var(--plyr-control-spacing, 10px) * .7);
  margin-bottom: 3.5px;
  margin-bottom: calc(var(--plyr-control-spacing, 10px) * .7 / 2);
  padding-left: 28px;
  padding-left: calc(var(--plyr-control-spacing, 10px) * .7 * 4);
  width: calc(100% - var(--plyr-control-spacing, 10px) * .7 * 2);
  width: calc(100% - 14px);
  position: relative;
}

.plyr__menu__container .plyr__control--back:after {
  border-right-color: #728197;
  border-right-color: var(--plyr-menu-arrow-color, #728197);
  left: 6.5px;
  left: calc(var(--plyr-control-spacing, 10px) * .7 * 1.5 - var(--plyr-menu-item-arrow-size, 4px));
}

.plyr__menu__container .plyr__control--back:before {
  background: #dcdfe5;
  background: var(--plyr-menu-back-border-color, #dcdfe5);
  box-shadow: 0 1px #fff;
  box-shadow: 0 1px 0 var(--plyr-menu-back-border-shadow-color, #fff);
  content: "";
  margin-top: 3.5px;
  margin-top: calc(var(--plyr-control-spacing, 10px) * .7 / 2);
  height: 1px;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  overflow: hidden;
}

.plyr__menu__container .plyr__control--back.plyr__tab-focus:after, .plyr__menu__container .plyr__control--back:hover:after {
  border-right-color: currentColor;
}

.plyr__menu__container .plyr__control[role="menuitemradio"] {
  padding-left: 7px;
  padding-left: calc(var(--plyr-control-spacing, 10px) * .7);
}

.plyr__menu__container .plyr__control[role="menuitemradio"]:after, .plyr__menu__container .plyr__control[role="menuitemradio"]:before {
  border-radius: 100%;
}

.plyr__menu__container .plyr__control[role="menuitemradio"]:before {
  content: "";
  margin-right: 10px;
  margin-right: var(--plyr-control-spacing, 10px);
  background: #0000001a;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  transition: all .3s;
  display: block;
}

.plyr__menu__container .plyr__control[role="menuitemradio"]:after {
  opacity: 0;
  background: #fff;
  border: 0;
  width: 6px;
  height: 6px;
  transition: transform .3s, opacity .3s;
  top: 50%;
  left: 12px;
  transform: translateY(-50%)scale(0);
}

.plyr__menu__container .plyr__control[role="menuitemradio"][aria-checked="true"]:before {
  background: #00b2ff;
  background: var(--plyr-control-toggle-checked-background, var(--plyr-color-main, var(--plyr-color-main, #00b2ff)));
}

.plyr__menu__container .plyr__control[role="menuitemradio"][aria-checked="true"]:after {
  opacity: 1;
  transform: translateY(-50%)scale(1);
}

.plyr__menu__container .plyr__control[role="menuitemradio"].plyr__tab-focus:before, .plyr__menu__container .plyr__control[role="menuitemradio"]:hover:before {
  background: #23282f1a;
}

.plyr__menu__container .plyr__menu__value {
  margin-left: auto;
  margin-right: -5px;
  margin-right: calc(var(--plyr-control-spacing, 10px) * .7 * -1 - -2px);
  padding-left: 24.5px;
  padding-left: calc(var(--plyr-control-spacing, 10px) * .7 * 3.5);
  pointer-events: none;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.plyr--full-ui input[type="range"] {
  -ms-appearance: none;
  appearance: none;
  border-radius: 26px;
  border-radius: calc(var(--plyr-range-thumb-height, 13px) * 2);
  color: var(--plyr-range-fill-background, var(--plyr-color-main, var(--plyr-color-main, #00b2ff)));
  height: calc(var(--plyr-range-thumb-active-shadow-width, 3px) * 2 + var(--plyr-range-thumb-height, 13px));
  background: none;
  border: 0;
  width: 100%;
  min-width: 0;
  height: 19px;
  margin: 0;
  padding: 0;
  transition: box-shadow .3s;
  display: block;
}

.plyr--full-ui input[type="range"]::-webkit-slider-runnable-track {
  background: linear-gradient(90deg, currentColor 0, #0000 0);
  background-image: linear-gradient(to right, currentColor var(--value, 0), transparent var(--value, 0));
  border-radius: 2.5px;
  border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
  height: var(--plyr-range-track-height, 5px);
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0;
  height: 5px;
  transition: box-shadow .3s;
}

.plyr--full-ui input[type="range"]::-webkit-slider-thumb {
  -ms-appearance: none;
  appearance: none;
  background: #fff;
  background: var(--plyr-range-thumb-background, #fff);
  box-shadow: 0 1px 1px #23282f26, 0 0 0 1px #23282f33;
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33);
  height: var(--plyr-range-thumb-height, 13px);
  margin-top: -4px;
  margin-top: calc((var(--plyr-range-thumb-height, 13px)  - var(--plyr-range-track-height, 5px)) / 2 * -1);
  width: var(--plyr-range-thumb-height, 13px);
  border: 0;
  border-radius: 100%;
  width: 13px;
  height: 13px;
  transition: all .2s;
  position: relative;
}

.plyr--full-ui input[type="range"]::-moz-range-track {
  border-radius: 2.5px;
  border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
  height: var(--plyr-range-track-height, 5px);
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: none;
  border: 0;
  height: 5px;
  transition: box-shadow .3s;
}

.plyr--full-ui input[type="range"]::-moz-range-thumb {
  background: #fff;
  background: var(--plyr-range-thumb-background, #fff);
  box-shadow: 0 1px 1px #23282f26, 0 0 0 1px #23282f33;
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33);
  height: var(--plyr-range-thumb-height, 13px);
  width: var(--plyr-range-thumb-height, 13px);
  border: 0;
  border-radius: 100%;
  width: 13px;
  height: 13px;
  transition: all .2s;
  position: relative;
}

.plyr--full-ui input[type="range"]::-moz-range-progress {
  border-radius: 2.5px;
  border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
  height: var(--plyr-range-track-height, 5px);
  background: currentColor;
  height: 5px;
}

.plyr--full-ui input[type="range"]::-ms-track {
  color: #0000;
}

.plyr--full-ui input[type="range"]::-ms-fill-upper {
  border-radius: 2.5px;
  border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
  height: var(--plyr-range-track-height, 5px);
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: none;
  border: 0;
  height: 5px;
  transition: box-shadow .3s;
}

.plyr--full-ui input[type="range"]::-ms-track {
  border-radius: 2.5px;
  border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
  height: var(--plyr-range-track-height, 5px);
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: none;
  border: 0;
  height: 5px;
  transition: box-shadow .3s;
}

.plyr--full-ui input[type="range"]::-ms-fill-lower {
  border-radius: 2.5px;
  border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
  height: var(--plyr-range-track-height, 5px);
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: currentColor;
  border: 0;
  height: 5px;
  transition: box-shadow .3s;
}

.plyr--full-ui input[type="range"]::-ms-thumb {
  background: #fff;
  background: var(--plyr-range-thumb-background, #fff);
  box-shadow: 0 1px 1px #23282f26, 0 0 0 1px #23282f33;
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33);
  height: var(--plyr-range-thumb-height, 13px);
  width: var(--plyr-range-thumb-height, 13px);
  border: 0;
  border-radius: 100%;
  width: 13px;
  height: 13px;
  margin-top: 0;
  transition: all .2s;
  position: relative;
}

.plyr--full-ui input[type="range"]::-ms-tooltip {
  display: none;
}

.plyr--full-ui input[type="range"]::-moz-focus-outer {
  border: 0;
}

.plyr--full-ui input[type="range"]:focus {
  outline: 0;
}

.plyr--full-ui input[type="range"].plyr__tab-focus::-webkit-slider-runnable-track {
  outline: 3px dotted #00b2ff;
  outline: var(--plyr-tab-focus-color, var(--plyr-color-main, var(--plyr-color-main, #00b2ff))) dotted 3px;
  outline-offset: 2px;
}

.plyr--full-ui input[type="range"].plyr__tab-focus::-moz-range-track {
  outline: 3px dotted #00b2ff;
  outline: var(--plyr-tab-focus-color, var(--plyr-color-main, var(--plyr-color-main, #00b2ff))) dotted 3px;
  outline-offset: 2px;
}

.plyr--full-ui input[type="range"].plyr__tab-focus::-ms-track {
  outline: 3px dotted #00b2ff;
  outline: var(--plyr-tab-focus-color, var(--plyr-color-main, var(--plyr-color-main, #00b2ff))) dotted 3px;
  outline-offset: 2px;
}

.plyr__poster {
  background-color: #000;
  background-color: var(--plyr-video-background, var(--plyr-video-background, #000));
  opacity: 0;
  z-index: 1;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
  transition: opacity .2s;
  position: absolute;
  top: 0;
  left: 0;
}

.plyr--stopped.plyr__poster-enabled .plyr__poster {
  opacity: 1;
}

.plyr--youtube.plyr--paused.plyr__poster-enabled:not(.plyr--stopped) .plyr__poster {
  display: none;
}

.plyr__time {
  font-size: 13px;
  font-size: var(--plyr-font-size-time, var(--plyr-font-size-small, 13px));
}

.plyr__time + .plyr__time:before {
  content: "⁄";
  margin-right: 10px;
  margin-right: var(--plyr-control-spacing, 10px);
}

@media (width <= 767px) {
  .plyr__time + .plyr__time {
    display: none;
  }
}

.plyr__tooltip {
  background: #ffffffe6;
  background: var(--plyr-tooltip-background, #ffffffe6);
  border-radius: 5px;
  border-radius: var(--plyr-tooltip-radius, 5px);
  box-shadow: 0 1px 2px #00000026;
  box-shadow: var(--plyr-tooltip-shadow, 0 1px 2px #00000026);
  color: var(--plyr-tooltip-color, #4a5464);
  font-size: 13px;
  font-size: var(--plyr-font-size-small, 13px);
  font-weight: 400;
  font-weight: var(--plyr-font-weight-regular, 400);
  margin-bottom: 10px;
  margin-bottom: calc(var(--plyr-control-spacing, 10px) / 2 * 2);
  opacity: 0;
  padding: 5px 7.5px;
  padding: calc(var(--plyr-control-spacing, 10px) / 2) calc(var(--plyr-control-spacing, 10px) / 2 * 1.5);
  pointer-events: none;
  transform-origin: 50% 100%;
  white-space: nowrap;
  z-index: 2;
  line-height: 1.3;
  transition: transform .2s .1s, opacity .2s .1s;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, 10px)scale(.8);
}

.plyr__tooltip:before {
  border-left: 4px solid #0000;
  border-left: var(--plyr-tooltip-arrow-size, 4px) solid transparent;
  border-right: 4px solid #0000;
  border-right: var(--plyr-tooltip-arrow-size, 4px) solid transparent;
  border-top: 4px solid #ffffffe6;
  border-top: var(--plyr-tooltip-arrow-size, 4px) solid var(--plyr-tooltip-background, #ffffffe6);
  bottom: -4px;
  bottom: calc(var(--plyr-tooltip-arrow-size, 4px) * -1);
  content: "";
  z-index: 2;
  width: 0;
  height: 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.plyr .plyr__control.plyr__tab-focus .plyr__tooltip, .plyr .plyr__control:hover .plyr__tooltip, .plyr__tooltip--visible {
  opacity: 1;
  transform: translate(-50%)scale(1);
}

.plyr .plyr__control:hover .plyr__tooltip {
  z-index: 3;
}

.plyr__controls > .plyr__control:first-child .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip {
  transform-origin: 0 100%;
  left: 0;
  transform: translateY(10px)scale(.8);
}

.plyr__controls > .plyr__control:first-child .plyr__tooltip:before, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip:before {
  left: 16px;
  left: calc(var(--plyr-control-icon-size, 18px) / 2 + var(--plyr-control-spacing, 10px) * .7);
}

.plyr__controls > .plyr__control:last-child .plyr__tooltip {
  transform-origin: 100% 100%;
  left: auto;
  right: 0;
  transform: translateY(10px)scale(.8);
}

.plyr__controls > .plyr__control:last-child .plyr__tooltip:before {
  left: auto;
  right: 16px;
  right: calc(var(--plyr-control-icon-size, 18px) / 2 + var(--plyr-control-spacing, 10px) * .7);
  transform: translateX(50%);
}

.plyr__controls > .plyr__control:first-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control:hover .plyr__tooltip, .plyr__controls > .plyr__control:first-child.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:first-child:hover .plyr__tooltip, .plyr__controls > .plyr__control:last-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:last-child.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:last-child:hover .plyr__tooltip {
  transform: translate(0)scale(1);
}

.plyr__progress {
  left: 6.5px;
  left: calc(var(--plyr-range-thumb-height, 13px) * .5);
  margin-right: 13px;
  margin-right: var(--plyr-range-thumb-height, 13px);
  position: relative;
}

.plyr__progress__buffer, .plyr__progress input[type="range"] {
  margin-left: -6.5px;
  margin-left: calc(var(--plyr-range-thumb-height, 13px) * -.5);
  margin-right: -6.5px;
  margin-right: calc(var(--plyr-range-thumb-height, 13px) * -.5);
  width: calc(100% + var(--plyr-range-thumb-height, 13px));
  width: calc(100% + 13px);
}

.plyr__progress input[type="range"] {
  z-index: 2;
  position: relative;
}

.plyr__progress .plyr__tooltip {
  overflow-wrap: break-word;
  max-width: 120px;
  left: 0;
}

.plyr__progress__buffer {
  -webkit-appearance: none;
  height: var(--plyr-range-track-height, 5px);
  margin-top: -2.5px;
  margin-top: calc((var(--plyr-range-track-height, 5px) / 2) * -1);
  background: none;
  border: 0;
  border-radius: 100px;
  height: 5px;
  padding: 0;
  position: absolute;
  top: 50%;
  left: 0;
}

.plyr__progress__buffer::-webkit-progress-bar {
  background: none;
}

.plyr__progress__buffer::-webkit-progress-value {
  min-width: var(--plyr-range-track-height, 5px);
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  transition: width .2s;
}

.plyr__progress__buffer::-moz-progress-bar {
  min-width: var(--plyr-range-track-height, 5px);
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  transition: width .2s;
}

.plyr__progress__buffer::-ms-fill {
  border-radius: 100px;
  transition: width .2s;
}

.plyr--loading .plyr__progress__buffer {
  background-image: linear-gradient(-45deg, #23282f99 25%, #0000 0 50%, #23282f99 0 75%, #0000 0, #0000);
  background-image: linear-gradient(-45deg, var(--plyr-progress-loading-background, #23282f99) 25%, transparent 25%, transparent 50%, var(--plyr-progress-loading-background, #23282f99) 50%, var(--plyr-progress-loading-background, #23282f99) 75%, transparent 75%, transparent);
  background-repeat: repeat-x;
  background-size: 25px 25px;
  background-size: var(--plyr-progress-loading-size, 25px) var(--plyr-progress-loading-size, 25px);
  color: #0000;
  animation: 1s linear infinite plyr-progress;
}

.plyr--video.plyr--loading .plyr__progress__buffer {
  background-color: #ffffff40;
  background-color: var(--plyr-video-progress-buffered-background, #ffffff40);
}

.plyr--audio.plyr--loading .plyr__progress__buffer {
  background-color: #c1c8d199;
  background-color: var(--plyr-audio-progress-buffered-background, #c1c8d199);
}

.plyr__progress__marker {
  background-color: #fff;
  background-color: var(--plyr-progress-marker-background, #fff);
  height: var(--plyr-range-track-height, 5px);
  width: var(--plyr-progress-marker-width, 3px);
  z-index: 3;
  border-radius: 1px;
  width: 3px;
  height: 5px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.plyr__volume {
  align-items: center;
  width: 20%;
  min-width: 80px;
  max-width: 110px;
  display: flex;
  position: relative;
}

.plyr__volume input[type="range"] {
  margin-left: 5px;
  margin-left: calc(var(--plyr-control-spacing, 10px) / 2);
  margin-right: 5px;
  margin-right: calc(var(--plyr-control-spacing, 10px) / 2);
  z-index: 2;
  position: relative;
}

.plyr--is-ios .plyr__volume {
  width: auto;
  min-width: 0;
}

.plyr--audio {
  display: block;
}

.plyr--audio .plyr__controls {
  background: #fff;
  background: var(--plyr-audio-controls-background, #fff);
  border-radius: inherit;
  color: var(--plyr-audio-control-color, #4a5464);
  padding: 10px;
  padding: var(--plyr-control-spacing, 10px);
}

.plyr--audio .plyr__control.plyr__tab-focus, .plyr--audio .plyr__control:hover, .plyr--audio .plyr__control[aria-expanded="true"] {
  background: #00b2ff;
  background: var(--plyr-audio-control-background-hover, var(--plyr-color-main, var(--plyr-color-main, #00b2ff)));
  color: var(--plyr-audio-control-color-hover, #fff);
}

.plyr--full-ui.plyr--audio input[type="range"]::-webkit-slider-runnable-track {
  background-color: #c1c8d199;
  background-color: var(--plyr-audio-range-track-background, var(--plyr-audio-progress-buffered-background, #c1c8d199));
}

.plyr--full-ui.plyr--audio input[type="range"]::-moz-range-track {
  background-color: #c1c8d199;
  background-color: var(--plyr-audio-range-track-background, var(--plyr-audio-progress-buffered-background, #c1c8d199));
}

.plyr--full-ui.plyr--audio input[type="range"]::-ms-track {
  background-color: #c1c8d199;
  background-color: var(--plyr-audio-range-track-background, var(--plyr-audio-progress-buffered-background, #c1c8d199));
}

.plyr--full-ui.plyr--audio input[type="range"]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px #23282f26, 0 0 0 1px #23282f33, 0 0 0 3px #23282f1a;
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, #23282f1a);
}

.plyr--full-ui.plyr--audio input[type="range"]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px #23282f26, 0 0 0 1px #23282f33, 0 0 0 3px #23282f1a;
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, #23282f1a);
}

.plyr--full-ui.plyr--audio input[type="range"]:active::-ms-thumb {
  box-shadow: 0 1px 1px #23282f26, 0 0 0 1px #23282f33, 0 0 0 3px #23282f1a;
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, #23282f1a);
}

.plyr--audio .plyr__progress__buffer {
  color: var(--plyr-audio-progress-buffered-background, #c1c8d199);
}

.plyr--video {
  background: #000;
  background: var(--plyr-video-background, var(--plyr-video-background, #000));
  overflow: hidden;
}

.plyr--video.plyr--menu-open {
  overflow: visible;
}

.plyr__video-wrapper {
  background: #000;
  background: var(--plyr-video-background, var(--plyr-video-background, #000));
  width: 100%;
  height: 100%;
  margin: auto;
  position: relative;
  overflow: hidden;
}

.plyr__video-embed, .plyr__video-wrapper--fixed-ratio {
  aspect-ratio: 16 / 9;
}

@supports not (aspect-ratio: 16/9) {
  .plyr__video-embed, .plyr__video-wrapper--fixed-ratio {
    height: 0;
    padding-bottom: 56.25%;
    position: relative;
  }
}

.plyr__video-embed iframe, .plyr__video-wrapper--fixed-ratio video {
  border: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.plyr--full-ui .plyr__video-embed > .plyr__video-embed__container {
  padding-bottom: 240%;
  position: relative;
  transform: translateY(-38.2813%);
}

.plyr--video .plyr__controls {
  background: linear-gradient(#0000, #000000bf);
  background: var(--plyr-video-controls-background, linear-gradient(transparent, #000000bf));
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  color: var(--plyr-video-control-color, #fff);
  padding: 5px;
  padding: calc(var(--plyr-control-spacing, 10px) / 2);
  padding-top: 20px;
  padding-top: calc(var(--plyr-control-spacing, 10px) * 2);
  z-index: 3;
  transition: opacity .4s ease-in-out, transform .4s ease-in-out;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

@media (width >= 480px) {
  .plyr--video .plyr__controls {
    padding: 10px;
    padding: var(--plyr-control-spacing, 10px);
    padding-top: 35px;
    padding-top: calc(var(--plyr-control-spacing, 10px) * 3.5);
  }
}

.plyr--video.plyr--hide-controls .plyr__controls {
  opacity: 0;
  pointer-events: none;
  transform: translateY(100%);
}

.plyr--video .plyr__control.plyr__tab-focus, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded="true"] {
  background: #00b2ff;
  background: var(--plyr-video-control-background-hover, var(--plyr-color-main, var(--plyr-color-main, #00b2ff)));
  color: var(--plyr-video-control-color-hover, #fff);
}

.plyr__control--overlaid {
  background: #00b2ff;
  background: var(--plyr-video-control-background-hover, var(--plyr-color-main, var(--plyr-color-main, #00b2ff)));
  color: var(--plyr-video-control-color, #fff);
  opacity: .9;
  padding: 15px;
  padding: calc(var(--plyr-control-spacing, 10px) * 1.5);
  z-index: 2;
  border: 0;
  border-radius: 100%;
  transition: all .3s;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.plyr__control--overlaid svg {
  position: relative;
  left: 2px;
}

.plyr__control--overlaid:focus, .plyr__control--overlaid:hover {
  opacity: 1;
}

.plyr--playing .plyr__control--overlaid {
  opacity: 0;
  visibility: hidden;
}

.plyr--full-ui.plyr--video .plyr__control--overlaid {
  display: block;
}

.plyr--full-ui.plyr--video input[type="range"]::-webkit-slider-runnable-track {
  background-color: #ffffff40;
  background-color: var(--plyr-video-range-track-background, var(--plyr-video-progress-buffered-background, #ffffff40));
}

.plyr--full-ui.plyr--video input[type="range"]::-moz-range-track {
  background-color: #ffffff40;
  background-color: var(--plyr-video-range-track-background, var(--plyr-video-progress-buffered-background, #ffffff40));
}

.plyr--full-ui.plyr--video input[type="range"]::-ms-track {
  background-color: #ffffff40;
  background-color: var(--plyr-video-range-track-background, var(--plyr-video-progress-buffered-background, #ffffff40));
}

.plyr--full-ui.plyr--video input[type="range"]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px #23282f26, 0 0 0 1px #23282f33, 0 0 0 3px #ffffff80;
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, #ffffff80);
}

.plyr--full-ui.plyr--video input[type="range"]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px #23282f26, 0 0 0 1px #23282f33, 0 0 0 3px #ffffff80;
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, #ffffff80);
}

.plyr--full-ui.plyr--video input[type="range"]:active::-ms-thumb {
  box-shadow: 0 1px 1px #23282f26, 0 0 0 1px #23282f33, 0 0 0 3px #ffffff80;
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, #ffffff80);
}

.plyr--video .plyr__progress__buffer {
  color: var(--plyr-video-progress-buffered-background, #ffffff40);
}

.plyr:-ms-fullscreen {
  background: #000;
  width: 100%;
  height: 100%;
  margin: 0;
  border-radius: 0 !important;
}

.plyr:fullscreen {
  background: #000;
  width: 100%;
  height: 100%;
  margin: 0;
  border-radius: 0 !important;
}

.plyr:-ms-fullscreen video {
  height: 100%;
}

.plyr:fullscreen video {
  height: 100%;
}

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr:fullscreen .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr:fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr:-ms-fullscreen.plyr--hide-controls {
  cursor: none;
}

.plyr:fullscreen.plyr--hide-controls {
  cursor: none;
}

@media (width >= 1024px) {
  .plyr:-ms-fullscreen .plyr__captions {
    font-size: 21px;
    font-size: var(--plyr-font-size-xlarge, 21px);
  }

  .plyr:fullscreen .plyr__captions {
    font-size: 21px;
    font-size: var(--plyr-font-size-xlarge, 21px);
  }
}

.plyr--fullscreen-fallback {
  z-index: 10000000;
  background: #000;
  width: 100%;
  height: 100%;
  margin: 0;
  display: block;
  position: fixed;
  inset: 0;
  border-radius: 0 !important;
}

.plyr--fullscreen-fallback video {
  height: 100%;
}

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr--fullscreen-fallback.plyr--hide-controls {
  cursor: none;
}

@media (width >= 1024px) {
  .plyr--fullscreen-fallback .plyr__captions {
    font-size: 21px;
    font-size: var(--plyr-font-size-xlarge, 21px);
  }
}

.plyr__ads {
  border-radius: inherit;
  cursor: pointer;
  z-index: -1;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.plyr__ads > div, .plyr__ads > div iframe {
  width: 100%;
  height: 100%;
  position: absolute;
}

.plyr__ads:after {
  bottom: 10px;
  bottom: var(--plyr-control-spacing, 10px);
  color: #fff;
  content: attr(data-badge-text);
  pointer-events: none;
  right: 10px;
  right: var(--plyr-control-spacing, 10px);
  z-index: 3;
  background: #23282f;
  border-radius: 2px;
  padding: 2px 6px;
  font-size: 11px;
  position: absolute;
}

.plyr__ads:empty:after {
  display: none;
}

.plyr__cues {
  height: var(--plyr-range-track-height, 5px);
  opacity: .8;
  z-index: 3;
  background: currentColor;
  width: 3px;
  height: 5px;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.plyr__preview-thumb {
  background-color: #ffffffe6;
  background-color: var(--plyr-tooltip-background, #ffffffe6);
  border-radius: 5px;
  border-radius: var(--plyr-tooltip-radius, 5px);
  box-shadow: 0 1px 2px #00000026;
  box-shadow: var(--plyr-tooltip-shadow, 0 1px 2px #00000026);
  margin-bottom: 10px;
  margin-bottom: calc(var(--plyr-control-spacing, 10px) / 2 * 2);
  opacity: 0;
  pointer-events: none;
  transform-origin: 50% 100%;
  z-index: 2;
  padding: 3px;
  transition: transform .2s .1s, opacity .2s .1s;
  position: absolute;
  bottom: 100%;
  transform: translateY(10px)scale(.8);
}

.plyr__preview-thumb--is-shown {
  opacity: 1;
  transform: translate(0)scale(1);
}

.plyr__preview-thumb:before {
  border-left: 4px solid #0000;
  border-left: var(--plyr-tooltip-arrow-size, 4px) solid transparent;
  border-right: 4px solid #0000;
  border-right: var(--plyr-tooltip-arrow-size, 4px) solid transparent;
  border-top: 4px solid #ffffffe6;
  border-top: var(--plyr-tooltip-arrow-size, 4px) solid var(--plyr-tooltip-background, #ffffffe6);
  bottom: -4px;
  bottom: calc(var(--plyr-tooltip-arrow-size, 4px) * -1);
  content: "";
  left: calc(50% + var(--preview-arrow-offset));
  z-index: 2;
  width: 0;
  height: 0;
  position: absolute;
  transform: translateX(-50%);
}

.plyr__preview-thumb__image-container {
  border-radius: 4px;
  border-radius: calc(var(--plyr-tooltip-radius, 5px)  - 1px);
  z-index: 0;
  background: #c1c8d1;
  position: relative;
  overflow: hidden;
}

.plyr__preview-thumb__image-container img, .plyr__preview-thumb__image-container:after {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.plyr__preview-thumb__image-container:after {
  border-radius: inherit;
  content: "";
  pointer-events: none;
  box-shadow: inset 0 0 0 1px #00000026;
}

.plyr__preview-thumb__image-container img {
  max-width: none;
  max-height: none;
}

.plyr__preview-thumb__time-container {
  background: linear-gradient(#0000, #000000bf);
  background: var(--plyr-video-controls-background, linear-gradient(transparent, #000000bf));
  border-bottom-left-radius: 4px;
  border-bottom-left-radius: calc(var(--plyr-tooltip-radius, 5px)  - 1px);
  border-bottom-right-radius: 4px;
  border-bottom-right-radius: calc(var(--plyr-tooltip-radius, 5px)  - 1px);
  z-index: 3;
  padding: 20px 6px 6px;
  line-height: 1.1;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.plyr__preview-thumb__time-container span {
  color: #fff;
  font-size: 13px;
  font-size: var(--plyr-font-size-time, var(--plyr-font-size-small, 13px));
}

.plyr__preview-scrubbing {
  filter: blur(1px);
  opacity: 0;
  pointer-events: none;
  z-index: 1;
  width: 100%;
  height: 100%;
  margin: auto;
  transition: opacity .3s;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.plyr__preview-scrubbing--is-shown {
  opacity: 1;
}

.plyr__preview-scrubbing img {
  -o-object-fit: contain;
  object-fit: contain;
  width: 100%;
  max-width: none;
  height: 100%;
  max-height: none;
  position: absolute;
  top: 0;
  left: 0;
}

.plyr--no-transition {
  transition: none !important;
}

.plyr__sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  border: 0 !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  position: absolute !important;
}

.plyr [hidden] {
  display: none !important;
}

.CircularProgressbar {
  vertical-align: middle;
  width: 100%;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #3e98c7;
  stroke-linecap: round;
  transition: stroke-dashoffset .5s;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: #d6d6d6;
  stroke-linecap: round;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #3e98c7;
  dominant-baseline: middle;
  text-anchor: middle;
  font-size: 20px;
}

.CircularProgressbar .CircularProgressbar-background {
  fill: #d6d6d6;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-background {
  fill: #3e98c7;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-text {
  fill: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-path {
  stroke: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-trail {
  stroke: #0000;
}

@font-face {
  font-family: Charlie Text;
  src: url("CharlieText-Regular.194d8954.194d8954.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Charlie Text;
  src: url("CharlieText-Semibold.525e99e1.525e99e1.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Charlie Text;
  src: url("CharlieText-Bold.ef526fd8.ef526fd8.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Charlie Display;
  src: url("CharlieDisplay-Regular.e90dd3db.e90dd3db.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Charlie Display;
  src: url("CharlieDisplay-Semibold.596814d5.596814d5.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Charlie Display;
  src: url("CharlieDisplay-Bold.f3ccefdb.f3ccefdb.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Inter;
  src: url("Inter-Regular.30288fd8.30288fd8.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Inter;
  src: url("Inter-SemiBold.f5225417.f5225417.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  font-family: Charlie Text, sans-serif;
}
/*# sourceMappingURL=index.cd35a20d.css.map */
